<template>
  <span id="notesWraper">
    <span class="d-flex flex-row align-center justify-center">
      <v-col cols="6">
        <v-select
          :items="orderType"
          v-model="currentOrderType"
          item-text="text"
          item-value="value"
          class="mx-2"
          label="Affichage"
          @change="onOrderTypeChange"
        ></v-select>
      </v-col>
    </span>
    <v-col
      cols="12"
      class="d-flex flex-row align-center justify-center flex-wrap"
    >
      <v-btn
        class="ma-2"
        color="success"
        outlined
        @click="exportTable"
        v-if="orderedChallenges"
      >
        <v-icon>mdi-file-excel-outline</v-icon>
        <span>Exporter les notes</span>
      </v-btn>
    </v-col>
    <v-simple-table id="notes">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th v-for="team in orderedTeams">
            <h3 v-text="team.name"></h3>
          </th>
        </tr>
      </thead>
      <tfoot>
        <template v-for="category in Object.keys(orderedChallenges)">
          <tr
            class="primary white--text pointer"
            @click="toggleCategory(category)"
          >
            <th :colspan="orderedTeams.length + 1" class="bt-2 bl-2">
              <h2
                v-if="currentOrderType == 'period'"
                v-text="periodTable[category].name"
              ></h2>
              <h2 v-else v-text="category"></h2>
            </th>
            <th class="bt-2 br-2">
              <span class="d-flex align-center justify-end">
                <v-icon color="white" v-if="!isHide.includes(category)"
                  >mdi-minus</v-icon
                >
                <v-icon color="white" v-else>mdi-plus</v-icon>
              </span>
            </th>
          </tr>
          <template
            v-for="challenge in orderedChallenges[category].challenges"
            v-if="!isHide.includes(category)"
          >
            <tr v-if="challenge.noteType == 'note'">
              <th class="bl-2" rowspan="2">
                <v-icon color="accent" class="mr-2">mdi-school-outline</v-icon>
                <span v-text="challenge.name"></span>
              </th>
              <th
                v-text="'Note ( /' + parseFloat(challenge.noteMax) + ' )'"
              ></th>
              <!-- <td v-for="team, index in orderedTeams" v-text="challenge.correction[team.id].total" :class="index >= orderedTeams.length-1 ? 'br-2' : ''"></td> -->
              <td
                v-for="(team, index) in orderedTeams"
                :key="team.id"
                :class="index >= orderedTeams.length - 1 ? 'br-2' : ''"
              >
                <span
                  v-if="
                    challenge.correction &&
                    challenge.correction[team.id] &&
                    challenge.correction[team.id].total !== undefined
                  "
                >
                  {{ challenge.correction[team.id].total }}
                </span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <th class="bl-2" v-if="challenge.noteType == 'cash'">
                <v-icon color="success" class="mr-2">mdi-cash</v-icon>
                <span v-text="challenge.name"></span>
              </th>
              <th
                v-text="
                  'Subvention ( /' +
                  dataFormater.formatMoney(
                    parseFloat(challenge.subventionMax)
                  ) +
                  ' )'
                "
              ></th>
              <!-- <td v-for="team, index in orderedTeams" v-text="dataFormater.formatMoney(parseFloat(challenge.correction[team.id].subvention))" :class="index >= orderedTeams.length-1 ? 'br-2' : ''"></td> -->
              <td
                v-for="(team, index) in orderedTeams"
                :key="team.id"
                :class="index >= orderedTeams.length - 1 ? 'br-2' : ''"
              >
                <span
                  v-if="
                    challenge.correction &&
                    challenge.correction[team.id] &&
                    challenge.correction[team.id].subvention !== undefined
                  "
                >
                  {{
                    dataFormater.formatMoney(
                      parseFloat(challenge.correction[team.id].subvention)
                    )
                  }}
                </span>
                <span v-else>-</span>
              </td>
            </tr>
          </template>
          <tr class="grey lighten-4" v-if="categoryHasNote(category)">
            <th class="bb-2 bl-2" rowspan="3">
              <h2 class="text-right">SOUS TOTAL :</h2>
            </th>
            <th>
              <h3 v-text="'Note ( /' + getCategoryNote(category) + ' )'"></h3>
            </th>
            <th
              v-for="(team, index) in orderedTeams"
              :class="index >= orderedTeams.length - 1 ? 'br-2' : ''"
            >
              <h3 v-text="getCategoryNoteOfTeam(category, team)"></h3>
            </th>
          </tr>
          <tr class="grey lighten-4" v-if="categoryHasNote(category)">
            <th>
              <h3 v-text="'Moyenne ( /20)'"></h3>
            </th>
            <th
              v-for="(team, index) in orderedTeams"
              :class="index >= orderedTeams.length - 1 ? 'br-2' : ''"
            >
              <h3
                v-text="
                  Math.round(
                    (parseFloat(getCategoryNoteOfTeam(category, team)) /
                      parseFloat(getCategoryNote(category))) *
                      20 *
                      100
                  ) / 100
                "
              ></h3>
            </th>
          </tr>
          <tr class="grey lighten-4">
            <th class="bb-2 bl-2" v-if="!categoryHasNote(category)">
              <h2 class="text-right">SOUS TOTAL :</h2>
            </th>
            <th class="bb-2">
              <h3
                v-text="
                  'Subvention ( /' +
                  dataFormater.formatMoney(getCategorySubvention(category)) +
                  ' )'
                "
              ></h3>
            </th>
            <th
              v-for="(team, index) in orderedTeams"
              :class="index >= orderedTeams.length - 1 ? 'bb-2 br-2' : 'bb-2'"
            >
              <h3
                v-text="
                  dataFormater.formatMoney(
                    getCategorySubventionOfTeam(category, team)
                  )
                "
              ></h3>
            </th>
          </tr>
        </template>
        <tr class="blue lighten-5">
          <th class="bb-2 bl-2" rowspan="4">
            <h2 class="text-right">TOTAL :</h2>
          </th>
          <th>
            <h3 v-text="'Note ( /' + getTotalNote() + ' )'"></h3>
          </th>
          <th
            v-for="(team, index) in orderedTeams"
            :class="index >= orderedTeams.length - 1 ? 'br-2' : ''"
          >
            <h3 v-text="getTotalNoteOfTeam(team)"></h3>
          </th>
        </tr>
        <tr class="blue lighten-5">
          <th>
            <h3
              v-text="
                'Subvention ( /' +
                dataFormater.formatMoney(parseFloat(getTotalSubvention())) +
                ' )'
              "
            ></h3>
          </th>
          <th
            v-for="(team, index) in orderedTeams"
            :class="index >= orderedTeams.length - 1 ? 'br-2' : ''"
          >
            <h3
              v-text="
                dataFormater.formatMoney(
                  parseFloat(getTotalSubventionOfTeam(team))
                )
              "
            ></h3>
          </th>
        </tr>
        <tr class="blue lighten-5">
          <th>
            <h3>Taux d'obtention des subventions</h3>
          </th>
          <th
            v-for="(team, index) in orderedTeams"
            :class="index >= orderedTeams.length - 1 ? 'br-2' : ''"
          >
            <h3
              v-text="
                dataFormater.formatPercentage(
                  Math.round(
                    (parseFloat(getTotalSubventionOfTeam(team)) /
                      parseFloat(getTotalSubvention())) *
                      10000
                  ) / 100
                )
              "
            ></h3>
          </th>
        </tr>
        <tr class="blue lighten-5">
          <th class="bb-2">
            <h3>Moyenne (/20)</h3>
          </th>
          <th
            v-for="(team, index) in orderedTeams"
            :class="index >= orderedTeams.length - 1 ? 'bb-2 br-2' : 'bb-2'"
          >
            <h3
              v-text="
                Math.round(
                  (parseFloat(getTotalNoteOfTeam(team)) /
                    parseFloat(getTotalNote())) *
                    20 *
                    100
                ) / 100
              "
            ></h3>
          </th>
        </tr>
      </tfoot>
    </v-simple-table>
  </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";
import dataExtractor from "@/assets/functions/dataExtractor";
import downloader from "@/assets/functions/downloader.js";

import Challenge from "@/classes/Challenge";
import ChallengeCategory from "@/classes/ChallengeCategory";
import Period from "@/classes/Period";
import Session from "@/classes/Session";
import Univers from "@/classes/Univers";
import Team from "@/classes/Team";

export default {
  name: "ChallengesNotes",
  props: ["session", "profile"],
  setup() {
    return {
      dataFormater,
      dataExtractor,
    };
  },
  data() {
    return {
      unsub: [],
      periods: [],
      periodTable: {},
      challenges: [],
      categories: [],
      orderedTeams: [],
      teamTable: {},
      orderType: [
        {
          text: "Par periode",
          value: "period",
        },
        {
          text: "Par catégorie",
          value: "category",
        },
      ],
      currentOrderType: "period",
      isHide: [],
    };
  },
  created() {
    this.unsub.push(
      ChallengeCategory.listenAll((challengeCategories) => {
        this.categories = challengeCategories;
      })
    );
  },
  watch: {
    session: {
      async handler(val) {
        if (val) {
          this.unsub.push(
            Period.listenBySession(this.session.id, (periods) => {
              let tmpPeriods = periods;
              tmpPeriods.sort((a, b) => a.end - b.end);
              this.periods = tmpPeriods;
              this.periodTable = {};
              for (let period of this.periods) {
                this.periodTable[period.id] = period;
              }
              this.$forceUpdate();
            })
          );
          this.unsub.push(
            Challenge.listenBySession(this.session.id, async (challenges) => {
              this.challenges = challenges;
              this.challenges.sort((a, b) => a.end - b.end);
              this.$forceUpdate();
            })
          );
          let universes = await Univers.getBySession(this.session.id);
          for (let univers of universes) {
            let teams = await Team.getByUnivers(univers.id);
            for (let team of teams) {
              this.teamTable[team.id] = team;
            }
            this.orderedTeams = Object.values(this.teamTable);
            this.orderedTeams.sort((a, b) => a.name.localeCompare(b.name));
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    orderedChallenges() {
      let tmpChallenges = {};
      if (this.currentOrderType == "period") {
        for (let challenge of this.challenges) {
          if (!tmpChallenges[challenge.period]) {
            tmpChallenges[challenge.period] = {
              period: this.periodTable[challenge.period],
              challenges: [],
            };
          }
          tmpChallenges[challenge.period].challenges.push(challenge);
        }
      } else if (this.currentOrderType == "category") {
        for (let challenge of this.challenges) {
          if (!tmpChallenges[challenge.category]) {
            tmpChallenges[challenge.category] = {
              category: challenge.category,
              challenges: [],
            };
          }
          tmpChallenges[challenge.category].challenges.push(challenge);
        }
      }
      return tmpChallenges;
    },
  },
  methods: {
    exportTable() {
      dataExtractor.exportTable(
        "notesWraper",
        "notes",
        "Notes " +
          this.orderType
            .find((orderType) => orderType.value == this.currentOrderType)
            .text.toLowerCase()
      );
    },
    onOrderTypeChange() {
      this.isHide = [];
      this.$forceUpdate();
    },
    toggleCategory(category) {
      if (this.isHide.includes(category)) {
        this.isHide.splice(this.isHide.indexOf(category), 1);
      } else {
        this.isHide.push(category);
      }
      this.$forceUpdate();
    },
    categoryHasNote(category) {
      for (let challenge of this.orderedChallenges[category].challenges) {
        if (challenge.noteType == "note") {
          return true;
        }
      }
      return false;
    },
    getCategoryNote(category) {
      let note = 0;
      for (let challenge of this.orderedChallenges[category].challenges) {
        if (challenge.noteType == "note") {
          note += parseFloat(challenge.noteMax);
        }
      }
      return note;
    },
    getCategorySubvention(category) {
      let subvention = 0;
      for (let challenge of this.orderedChallenges[category].challenges) {
        subvention += parseFloat(challenge.subventionMax);
      }
      return subvention;
    },
    // getCategoryNoteOfTeam(category, team) {
    //   let note = 0;
    //   for (let challenge of this.orderedChallenges[category].challenges) {
    //     note += parseFloat(challenge.correction[team.id].total);
    //   }
    //   return note;
    // },

    getCategoryNoteOfTeam(category, team) {
      let note = 0;
      for (let challenge of this.orderedChallenges[category].challenges) {
        if (
          challenge.correction &&
          challenge.correction[team.id] &&
          challenge.correction[team.id].total !== undefined
        ) {
          note += parseFloat(challenge.correction[team.id].total);
        }
      }
      return note;
    },

    // getCategorySubventionOfTeam(category, team) {
    //   let subvention = 0;
    //   for (let challenge of this.orderedChallenges[category].challenges) {
    //     subvention += parseFloat(challenge.correction[team.id].subvention);
    //   }
    //   return subvention;
    // },

    getCategorySubventionOfTeam(category, team) {
      let subvention = 0;
      for (let challenge of this.orderedChallenges[category].challenges) {
        if (
          challenge.correction &&
          challenge.correction[team.id] &&
          challenge.correction[team.id].subvention !== undefined
        ) {
          subvention += parseFloat(challenge.correction[team.id].subvention);
        }
      }
      return subvention;
    },
    getTotalNote() {
      let note = 0;
      for (let challenge of this.challenges) {
        if (challenge.noteType == "note") {
          note += parseFloat(challenge.noteMax);
        }
      }
      return note;
    },
    getTotalSubvention() {
      let subvention = 0;
      for (let challenge of this.challenges) {
        subvention += parseFloat(challenge.subventionMax);
      }
      return subvention;
    },
    // getTotalNoteOfTeam(team) {
    //   let note = 0;
    //   for (let challenge of this.challenges) {
    //     note += parseFloat(challenge.correction[team.id].total);
    //   }
    //   return note;
    // },

    getTotalNoteOfTeam(team) {
      let note = 0;
      for (let challenge of this.challenges) {
        if (
          challenge.correction &&
          challenge.correction[team.id] &&
          challenge.correction[team.id].total !== undefined
        ) {
          note += parseFloat(challenge.correction[team.id].total);
        }
      }
      return note;
    },
    // getTotalSubventionOfTeam(team) {
    //   let subvention = 0;
    //   for (let challenge of this.challenges) {
    //     subvention += parseFloat(challenge.correction[team.id].subvention);
    //   }
    //   return subvention;
    // },
    getTotalSubventionOfTeam(team) {
      let subvention = 0;
      for (let challenge of this.challenges) {
        if (
          challenge.correction &&
          challenge.correction[team.id] &&
          challenge.correction[team.id].subvention !== undefined
        ) {
          subvention += parseFloat(challenge.correction[team.id].subvention);
        }
      }
      return subvention;
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
