<template>
  <span>
    <span class="d-flex flex-row align-center justify-center">
      <v-col cols="6">
        <v-select
          class="mx-2"
          :items="challenges"
          item-text="name"
          return-object
          v-model="selectedChallenge"
          label="Challenge"
          @change="onChallengeChange"
        ></v-select>
      </v-col>
      <v-col cols="6" v-if="selectedChallenge && teamTable">
        <v-select
          class="mx-2"
          :items="orderedTeams"
          return-object
          v-model="selectedTeam"
          label="Équipe"
        >
          <template v-slot:selection="{ item, index }">
            <span
              v-if="item"
              class="my-1"
              v-text="item.name + ' - ' + item.publicName"
            ></span>
          </template>
          <template v-slot:item="{ item, index }">
            <span
              v-if="item"
              class="my-1"
              v-text="item.name + ' - ' + item.publicName"
            ></span>
          </template>
        </v-select>
      </v-col>
    </span>
    <v-row class="pl-5 mt-5" v-if="selectedChallenge && selectedTeam">
      <v-col cols="12" md="6">
        <h2 class="mt-5 text-center">Correction :</h2>
        <v-simple-table
          v-if="
            selectedChallenge.noteType == 'note' &&
            selectedChallenge.correctionGrid.length > 0
          "
        >
          <thead>
            <tr>
              <th>Critere</th>
              <th class="text-center">note</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="criteria in selectedChallenge.correctionGrid">
              <td>
                <h4 v-text="criteria.name"></h4>
              </td>
              <td style="max-width: 50px">
                <v-text-field
                  class="my-1"
                  outlined
                  hide-details=""
                  :suffix="'/' + criteria.points"
                  v-model="
                    selectedChallenge.correction[selectedTeam.id].grid[
                      criteria.name.trim().replace(' ', '_')
                    ]
                  "
                  @change="onCorrectionChange"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th class="text-right" colspan="2">
                <h2
                  v-text="
                    'TOTAL : ' +
                    selectedChallenge.correction[selectedTeam.id].total +
                    ' / ' +
                    selectedChallenge.noteMax
                  "
                ></h2>
              </th>
            </tr>
            <tr>
              <th class="text-right" colspan="2">
                <h3
                  v-text="
                    '(' +
                    dataFormater.formatMoney(
                      selectedChallenge.correction[selectedTeam.id].subvention
                    ) +
                    ' / ' +
                    dataFormater.formatMoney(selectedChallenge.subventionMax) +
                    ')'
                  "
                ></h3>
              </th>
            </tr>
          </tbody>
        </v-simple-table>
        <v-simple-table v-else-if="selectedChallenge.noteType == 'note'">
          <thead>
            <tr>
              <th>Critere</th>
              <th class="text-center">note</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TOTAL :</td>
              <td>
                <v-text-field
                  class="my-1"
                  outlined
                  hide-details=""
                  :suffix="'/' + selectedChallenge.noteMax"
                  v-model="selectedChallenge.correction[selectedTeam.id].total"
                  @change="onCorrectionChange"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th class="text-right" colspan="2">
                <h3
                  v-text="
                    '(' +
                    dataFormater.formatMoney(
                      selectedChallenge.correction[selectedTeam.id].subvention
                    ) +
                    ' / ' +
                    dataFormater.formatMoney(selectedChallenge.subventionMax) +
                    ')'
                  "
                ></h3>
              </th>
            </tr>
          </tbody>
        </v-simple-table>
        <v-simple-table v-else-if="selectedChallenge.noteType == 'cash'">
          <thead>
            <tr>
              <th>Critere</th>
              <th class="text-center">subvention</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TOTAL :</td>
              <td>
                <v-text-field
                  class="my-1"
                  outlined
                  hide-details=""
                  :suffix="
                    '/' +
                    dataFormater.formatMoney(selectedChallenge.subventionMax)
                  "
                  v-model="
                    selectedChallenge.correction[selectedTeam.id].subvention
                  "
                  @change="onCorrectionChange"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <h2 class="mt-5 mb-2">Ajouter un commentaire :</h2>
        <v-textarea
          rows="3"
          outlined
          v-model="selectedChallenge.correction[selectedTeam.id].comment"
          @change="onCorrectionChange"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6">
        <h2 class="mt-5 text-center">Fichiers déposés :</h2>
        <v-simple-table>
          <thead>
            <tr>
              <th>Nom du fichier</th>
              <th>date de depot</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in teamResponse">
              <td v-text="file.originalName"></td>
              <td v-text="new Date(file.date).toLocaleString()"></td>
              <td>
                <v-btn color="primary" icon @click="downloadResponseFile(file)">
                  <v-icon>mdi-download-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <span
      class="mt-5 d-flex flex-column align-center justify-center"
      v-if="selectedChallenge && teamList && teamList.length > 0"
    >
      <v-btn color="primary" outlined @click="downloadAllResponses">
        <v-icon>mdi-download-outline</v-icon>
        <span>Telecharger tout les rendus</span>
      </v-btn>
    </span>
    <span v-else-if="selectedChallenge">
      <h2 class="accent--text text-center">
        Ce challenge ne comporte aucune reponse pour le moment
      </h2>
    </span>
    <span
      class="mt-5 d-flex flex-column align-center justify-center"
      v-if="selectedChallenge"
    >
      <v-switch
        v-model="selectedChallenge.correctionIsVisible"
        @change="onVisibilityChange"
        label="Rendre cette subvention de ce challenge visible aux utilisateurs"
      ></v-switch>
    </span>
  </span>
</template>

<script>
import { getDownloadURL, getStorage, ref, getBlob } from "firebase/storage";

import JSZip from "jszip";

import dataFormater from "@/assets/functions/dataFormater.js";
import downloader from "@/assets/functions/downloader.js";

import Challenge from "@/classes/Challenge";
import Period from "@/classes/Period";
import Univers from "@/classes/Univers";
import Team from "@/classes/Team";

export default {
  name: "ChallengesCorrection",
  props: ["session", "profile"],
  setup() {
    return {
      storage: getStorage(),
      dataFormater,
    };
  },
  data() {
    return {
      unsub: [],
      challenges: [],
      periods: [],
      teamTable: {},
      orderedTeams: [],
      selectedChallenge: null,
      selectedTeam: null,
      currentTab: null,
      fileUploadDialog: false,
      fileUploadData: {
        challenge: null,
        file: null,
      },
    };
  },
  watch: {
    session: {
      async handler(val) {
        if (val) {
          // reset to refresh challenges:
          this.teamTable = {};
          this.orderedTeams = [];
          this.selectedChallenge = null;
          this.selectedTeam = null;
          this.unsub.push(
            Period.listenBySession(this.session.id, (periods) => {
              let tmpPeriods = periods;
              tmpPeriods.sort((a, b) => a.end - b.end);
              this.periods = tmpPeriods;
            })
          );
          this.unsub.push(
            Challenge.listenBySession(this.session.id, async (challenges) => {
              this.challenges = challenges;
              for (let challenge of this.challenges) {
                if (challenge.fileRef) {
                  challenge.fileUrl = await getDownloadURL(
                    ref(this.storage, "challenge/" + challenge.fileRef)
                  );
                } else {
                  challenge.fileUrl = null;
                }
              }
              this.challenges.sort((a, b) => a.end - b.end);
              this.$forceUpdate();
            })
          );
          let universes = await Univers.getBySession(this.session.id);
          for (let univers of universes) {
            let teams = await Team.getByUnivers(univers.id);
            for (let team of teams) {
              this.teamTable[team.id] = team;
            }
            this.orderedTeams = Object.values(this.teamTable);
            this.orderedTeams.sort((a, b) => a.name.localeCompare(b.name));
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    teamList() {
      let teamList = [];
      for (let teamId of Object.keys(this.selectedChallenge.responses)) {
        if (this.teamTable[teamId]) {
          teamList.push(this.teamTable[teamId]);
        }
      }
      return teamList;
    },
    teamResponse() {
      if (this.selectedTeam) {
        return this.selectedChallenge.responses[this.selectedTeam.id];
      } else {
        return null;
      }
    },
  },
  methods: {
    onVisibilityChange() {
      this.selectedChallenge.save();
      this.$forceUpdate();
    },
    onChallengeChange() {
      this.selectedTeam = null;
      this.checkCorrection();
      this.onCorrectionChange();
      this.selectedChallenge.save();
      this.$forceUpdate();
    },
    onCorrectionChange() {
      this.computTotal();
      this.selectedChallenge.save();
      this.$forceUpdate();
    },
    async computTotal() {
      if (this.selectedChallenge) {
        // console.log("this.selectedChallenge", this.selectedChallenge);
        if (
          this.selectedChallenge.noteType == "note" &&
          this.selectedChallenge.correctionGrid &&
          this.selectedChallenge.correctionGrid.length > 0
        ) {
          for (let teamId in this.selectedChallenge.correction) {
            this.selectedChallenge.correction[teamId].total = 0;
            for (let criteria of this.selectedChallenge.correctionGrid) {
              let criteriaId = criteria.name.trim().replaceAll(" ", "_");
              this.selectedChallenge.correction[teamId].total += parseFloat(
                this.selectedChallenge.correction[teamId].grid[criteriaId]
              );
            }
            this.selectedChallenge.correction[teamId].subvention =
              Math.round(
                (parseFloat(this.selectedChallenge.correction[teamId].total) /
                  parseInt(this.selectedChallenge.noteMax)) *
                  parseFloat(this.selectedChallenge.subventionMax) *
                  100
              ) / 100;
          }
        } else if (this.selectedChallenge.noteType == "note") {
          for (let teamId in this.selectedChallenge.correction) {
            this.selectedChallenge.correction[teamId].subvention =
              Math.round(
                (parseInt(this.selectedChallenge.correction[teamId].total) /
                  parseInt(this.selectedChallenge.noteMax)) *
                  parseFloat(this.selectedChallenge.subventionMax) *
                  100
              ) / 100;
          }
        }
      }
    },
    async checkCorrection() {
      if (this.selectedChallenge) {
        for (let teamId in this.teamTable) {
          if (
            !Object.keys(this.selectedChallenge.correction).includes(teamId)
          ) {
            this.selectedChallenge.correction[teamId] = {
              comment: "",
              grid: {},
              total: 0,
              subvention: 0,
            };
          }
          for (let criteria of this.selectedChallenge.correctionGrid) {
            let criteriaId = criteria.name.trim().replaceAll(" ", "_");
            if (
              !Object.keys(
                this.selectedChallenge.correction[teamId].grid
              ).includes(criteriaId)
            ) {
              this.selectedChallenge.correction[teamId].grid[criteriaId] = 0;
            }
          }
        }
        await this.selectedChallenge.save();
      }
    },
    async downloadResponseFile(file) {
      if (file.fileRef) {
        let name =
          (
            this.selectedTeam.name +
            "-" +
            this.selectedTeam.publicName +
            "-" +
            this.selectedChallenge.name +
            "-" +
            new Date(file.date).toLocaleString()
          ).replaceAll(" ", "_") +
          "." +
          file.originalName.split(".").pop();
        await downloader.downloadfromStorage(
          ref(this.storage, "challengeResponse/" + file.fileRef),
          name
        );
      }
    },
    async downloadAllResponses() {
      let zip = new JSZip();
      for (let teamId of Object.keys(this.selectedChallenge.responses)) {
        let team = this.teamTable[teamId];
        let response = this.selectedChallenge.responses[teamId];
        if (response && response.length > 0) {
          for (let file of response) {
            if (file.fileRef) {
              const blob = await getBlob(
                ref(this.storage, "challengeResponse/" + file.fileRef)
              );
              let name =
                (
                  team.name +
                  "-" +
                  team.publicName +
                  "-" +
                  this.selectedChallenge.name +
                  "-" +
                  new Date(file.date).toLocaleString()
                )
                  .replaceAll(" ", "_")
                  .replaceAll("/", "-") +
                "." +
                file.originalName.split(".").pop();
              zip.file(name, blob);
            }
          }
        }
      }
      let zipBlob = await zip.generateAsync({ type: "blob" });
      downloader.downloadBlob(zipBlob, "test" + ".zip");
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
